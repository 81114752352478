
    import {Component, Vue} from 'vue-property-decorator';

    @Component({
        name: 'Navbar',
        components: {},
    })
    export default class extends Vue {
        get userName() {
            return localStorage.getItem('qymanagementusername')
        }

        async signOut() {
            const form = new FormData();
            const token = localStorage.getItem('qymanagementtoken') || '';
            form.append('access_token', token);

            // const res: any = await API_Login.signOut(form)

            localStorage.removeItem('qymanagementtoken');
            this.$router.push('/login');
        }

        editPsd() {
            this.$emit("clickEditPsd")
        }
    }
