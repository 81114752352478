
    import {Component, Vue} from 'vue-property-decorator';

    @Component({
        name: 'AppMain',
    })
    export default class extends Vue {
        get curTitle() {
            const route = this.$route;
            return this.$route.meta ? this.$route.meta.title : null;
        }
    }
