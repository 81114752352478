import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './router/meta';
import GlobalComponent from '@/components/global';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.use(GlobalComponent);

Vue.config.productionTip = false;
Vue.prototype.$size = 'medium';

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
