
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        name: 'Dialog',
    })
    export default class extends Vue {
        @Prop({required: true, default: '提示'}) title!: string;
        @Prop({default: '30%'}) width?: string;
        @Prop({default: true}) showBtn?: boolean;
        @Prop({default: false}) isCloseOnModal?: boolean;

        dialogVisible: boolean = false;

        show() {
            this.dialogVisible = true;
        }

        hide() {
            this.dialogVisible = false;
        }

        handleConfirm() {
            this.$emit('submitForm');
        }
    }
