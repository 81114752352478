import { Loading } from "element-ui";

const loadOption = { text: "请稍等", target: ".el-container", background: 'rgba(0, 0, 0, 0.2)' };

let loadingInstance: any = null;

export default class loadEvents {
  open() {
    loadingInstance = Loading.service(loadOption);
  }
  close() {
    loadingInstance && loadingInstance.close();
  }
}
