import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import _config from "@/config/config";
import {Foundation} from '@/utils/Foundation';
import {Message, MessageBox} from 'element-ui';

import loadEvents from "./Loading";

let loadingCount: number = 0;
const loadingInstance = new loadEvents();

interface AdminInstance<T = any> extends AxiosInstance {
    (config: AxiosRequestConfig): Promise<T>;
};

const service: AdminInstance = axios.create({
    baseURL: _config.BASE_URL,
    timeout: 30 * 1000,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    },
});

service.interceptors.request.use(
    (config: any) => {
        if (config.loading !== false) {
            loadingCount++;
            loadingInstance.open();
        }

        let token = localStorage.getItem("qymanagementtoken");
        if (token) {
            config.headers["Authorization"] = token;
        }

        return config;
    },
    (error) => {
        loadingCount = 0;
        loadingInstance.close();
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    async response => {
        loadingCount > 0 && loadingCount--;
        if (loadingCount == 0) {
            loadingInstance.close();
        }
        if (response.data.code == 200) {
            return response.data.results;
        } else {
            Message.error(response.data.error || Foundation.get(response, 'data.results.message', 'Error'));
            return Promise.reject(new Error(response.data.error || Foundation.get(response, 'data.results.message', 'Error')));
        }
    },
    async error => {
        const error_response = error.response || {};
        if (error_response.status == 441 || error_response.status == 442 || error_response.status == 401) {
            MessageBox.confirm(
                '当前登录已过期，您可以选择继续留在该页面，或者重新登录',
                '提示',
                {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                localStorage.removeItem('qymanagementtoken');
                location.reload();
            })
        } else {
            if (error_response.data && error_response.data.error) {
                Message.error(`${error_response.data.error}`);
            } else {
                Message.error(`${error}`);
            }
        }

        loadingCount = 0;
        loadingInstance.close();

        return Promise.reject(error);
    }
);

export default function Request<T>(options: any): Promise<T> {
    return service(options);
}