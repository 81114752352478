import Request from "../Request";

export default class ChangePassword {
    public static changePsd(data: any) {
        return Request({
            url: `/core/api/v1/admin/b/admin/resetpwdbys`,
            method: "post",
            data,
        });
    }
}
