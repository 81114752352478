
    import {Component, Vue} from 'vue-property-decorator';
    import SidebarItem from './SidebarItem.vue';
    import variables from '@/styles/_variables.scss';

    @Component({
        name: 'SideBar',
        components: {
            SidebarItem,
        },
    })
    export default class extends Vue {
        isCollapse: boolean = false;

        get variables() {
            return variables;
        }

        get activeMenu() {
            const route = this.$route;
            const {meta, path} = route;
            if (meta && meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        }

        get routes() {
            return (this.$router as any).options.routes;
        }
    }
