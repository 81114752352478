
import { Component, Vue, Prop } from "vue-property-decorator";
import _config from "@/config/config";
import AssetsApi from "@/utils/API/setting/AssetsApi";

@Component({})
export default class extends Vue {
    @Prop({ type: Boolean, default: true }) isSingle?: boolean;
    @Prop({ default: "" }) merchantId?: string;

    action: string = `${_config.BASE_URL}/core/api/v1/admin/c/assets/upload`;
    fileList: Array<any> = [];
    total: number = 0;
    params: any = {
        current: 1,
        size: 20,
    };
    checkedArr: Array<any> = [];
    isUpload = true;

    mounted() {
        this.getListData();
    }

    /*获取文件列表*/
    async getListData() {
        let res: any = await AssetsApi.getFileList(this.params);
        this.fileList =
            (res.records &&
                res.records.length > 0 &&
                res.records.map((e: any) => {
                    return e;
                })) ||
            [];
        this.total = res.total;
    }

    /*底部分页切换*/
    currentChange(pageNumber: number) {
        this.params.current = pageNumber;
        this.getListData();
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
        this.params.size = sizeNumber;
        this.getListData();
    }

    /*文件上传限制*/
    handleExceed(files: any) {
        this.$message.error(
            `最多选择10个文件，本次选择了 ${files.length} 个文件`
        );
    }

    /*图片上传之前*/
    beforeUpload(file: any) {
        let types = [
            "image/jpeg",
            "image/jpg",
            "image/gif",
            "image/bmp",
            "image/png",
        ];
        const isImage = types.includes(file.type);
        const isLt2M = file.size / 1024 < 300;
        if (!isImage) {
            this.$message.error("请上传图片!");
        }
        if (!isLt2M) {
            this.$message.error("上传图片大小不能超过300k!");
        }
        return isImage && isLt2M;
    }

    /*图片上传*/
    uploadImg(params: any) {
        this.isUpload = false;
        let formData = new FormData();
        formData.append("file", params.file);
        let id: any = this.merchantId;
        if (id != "") {
            formData.append("merchantId", id);
        }

        AssetsApi.uploadFile(formData)
            .then(() => {
                this.$message.success("操作成功");
                this.getListData();
            })
            .finally(() => {
                this.isUpload = true;
            });
    }

    /*勾选*/
    checkboxChange(e: any) {
        if (this.isSingle) {
            this.checkedArr = [e.pop()];
        }
    }

    show() {
        (this.$refs.imgChoice as any).show();
    }

    hide() {
        (this.$refs.imgChoice as any).hide();
    }

    submitForm() {
        const selectImg = this.fileList.filter((e: any) =>
            this.checkedArr.includes(e.id)
        );
        this.$emit("confirm", selectImg);
        this.hide();
    }
}
