import Vue from 'vue';
import Dialog from './Dialog.vue';
import Form from './Form.vue';
import Table from './Table.vue';
import Exports from './Exports.vue';
import ImgChoice from './ImgChoice.vue';

const components: any = {
    Dialog,
    Form,
    Table,
    Exports,
    ImgChoice
};

components.install = () => {
    Object.keys(components).forEach(key => {
        Vue.component(key, components[key]);
    })
};

export default components;
