
    import {Component, Vue} from 'vue-property-decorator';
    import {AppMain, Navbar, Sidebar, ChangePassword} from './components';

    @Component({
        name: 'Layout',
        components: {
            AppMain,
            Navbar,
            Sidebar,
            ChangePassword
        },
    })
    export default class extends Vue {
        editPsd() {
            (this.$refs.changePassword as any).show();
        }
    }
