
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        name: 'Form',
    })
    export default class extends Vue {
        @Prop({required: true}) formConfig: any;
        @Prop({default: true}) isInline?: boolean;
        @Prop({default: true}) isSearch?: boolean;
        @Prop({default: '140px'}) labelWidth?: string;
        @Prop({default: () => {}}) rules?: any;

        form: any = {};

        handleSearch() {
            this.$emit('searchForm', this.form);
        }
    }
