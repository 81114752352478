
    import {Component, Vue, Prop} from "vue-property-decorator";

    @Component({
        name: "Table",
    })
    export default class extends Vue {
        @Prop({required: true}) tableConfig!: Array<any>;
        @Prop({default: 0}) total!: number;
        @Prop({default: () => []}) tableData?: Array<any>;
        @Prop({default: true}) isNeedPage?: Boolean;
        @Prop({default: false}) isHighlight?: Boolean;
        @Prop({default: () => {return {page: 1, size: 20, sizes: [10,20,30,40,50,100]}}}) page?: any;
        @Prop({default: () => {return {}}}) props?: any;


        handleHighlight(row: any) {
            if (this.isHighlight) this.$emit("highlightChange", row);
        }

        handleCurrentChange(page_no: number) {
            this.page.page = page_no;
            this.$emit("currentChange", page_no);
        }

        handleSizeChange(page_size: number) {
            this.page.size = page_size;
            this.$emit("sizeChange", page_size);
        }

        handleSelectionChange(selection: any) {
            this.$emit("selectionChange", selection);
        }

        handleSortChange(event: any){
            this.$emit("handleSortChange", event);
        }

        selectable(event: any){
            return (this.$parent as any)?.selectable?.(event) === false ? false : true;
        }

        indexMethod(index: number) {
           return (this.page.page - 1) * this.page.size + index + 1;
        }
    }
