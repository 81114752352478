
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import API_Download from "@/utils/API/download/Download";
    import {Foundation} from "@/utils/Foundation";

    @Component({
        name: 'exports'
    })
    export default class extends Vue {
        @Prop() fieldsConfig?: Array<any>;
        @Prop() additional?: Array<any>;
        @Prop({default : true}) check?: boolean;

        checkFieldsList: any = [];
        checkAdditionalList: any = [];
        fields: any = [];
        form: any = {};
        isShowCheckBox: boolean = false;
        isLoading: boolean = false;

        @Watch("fieldsConfig", {immediate: true})
        setFields(){
            this.fields = [];
            this.checkFieldsList = [];
            if(this.fieldsConfig){
                for(let i = 0; i < this.fieldsConfig.length; i++){
                    if(this.fieldsConfig[i].prop){
                        this.fields.push({field: this.fieldsConfig[i].prop.replace("_", ""), name: this.fieldsConfig[i].label});
                    }
                }
            }

            this.isShowCheckBox = false;
            this.$nextTick(()=>{
              this.isShowCheckBox = true;
            })
        }

        show(exportForm: any) {
            (this.$refs.exports as any).show();
            this.form = Foundation.deepClone(exportForm);
        }

        hide() {
            (this.$refs.exports as any).hide();
        }

        async submitForm(){
            this.form.fields = Foundation.deepClone(this.checkFieldsList);// 导出字段
            if(this.additional) {   // 附加信息
                this.form.additional = {};
                for(let i = 0; i < this.additional.length; i++){
                    let prop: string = this.additional[i].prop;
                    this.form.additional[prop] = (this.checkAdditionalList.indexOf(prop) != -1);
                }
            }

            if (this.isLoading) return;
            this.isLoading = true;
            try {
                await API_Download.exports(this.form);
                this.isLoading = false;
                this.$message.success('已加入导出队列，稍后在下载中心下载');
                this.$emit('submitForm', this.form);
                this.hide();
            } catch (e) {
                this.isLoading = false;
            }
        }
    }
