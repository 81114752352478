
    import {Component, Vue} from "vue-property-decorator";
    import {_changePassword} from '@/utils/pageConfig/menu/_changePassword';
    import {Foundation} from '@/utils/Foundation';
    import API_ChangePassword from '@/utils/API/menu/ChangePassword';
    import {MyBase64} from '@/utils/MyBase64';

    @Component({})
    export default class extends Vue {
        pageConfig: any = _changePassword;
        isLoading: boolean = false;

        mounted() {
        }

        checkPassword() {
            let form: any = Foundation.deepClone((this.$refs.changePsdForm as any).form);
            if (form.newPassword != form.surePassword) {
                this.$message.warning("两次输入的密码不相同");
                return false;
            } else {
                return true;
            }
        }

        show() {
            (this.$refs.changePassword as any).show();
            this.$nextTick(() => {
                (this.$refs.changePsdForm as any).form = {};
                (this.$refs.changePsdForm as any).$refs.form.clearValidate();
            });
        }

        hide() {
            (this.$refs.changePassword as any).hide();
        }

        async submitForm() {
            let form = Foundation.resetParams((this.$refs.changePsdForm as any).form);

            (this.$refs.changePsdForm as any).$refs.form.validate(async (valid: any) => {
                if (valid && this.checkPassword()) {
                    if (this.isLoading) return;
                    this.isLoading = true;

                    try {
                        let formData: any = new FormData();
                        formData.append('oldPassword', MyBase64.Encode(form.password));
                        formData.append('newPassword', MyBase64.Encode(form.newPassword));
                        const res: any = await API_ChangePassword.changePsd(formData);
                        this.hide();
                        this.$message.success("操作成功");
                    }catch (e) {
                        this.isLoading = false;
                    }
                } else {
                    return false;
                }
            });
        }
    }
