import {password} from "@/utils/validate";

export const _changePassword = {
    //修改密码表单
    changePsdForm: [
        {
            type: 'el-input',
            label: '原密码：',
            value: 'password',
            props: {
                clearable: true,
                type: 'password'
            }
        },
        {
            type: 'el-input',
            label: '新密码：',
            value: 'newPassword',
            props: {
                clearable: true,
                type: 'password'
            }
        },
        {
            type: 'el-input',
            label: '确认密码：',
            value: 'surePassword',
            props: {
                clearable: true,
                type: 'password'
            }
        }
    ],
    //修改密码规则
    rulesChangePsd: {
        password: [{required: true, message: '请填写原密码', trigger: 'blur'}],
        newPassword: [{required: true, message: '请填写不少于6位的密码', trigger: 'blur', pattern: password}],
        surePassword: [{required: true, message: '请填写不少于6位的密码', trigger: 'blur', pattern: password}]
    }
};
