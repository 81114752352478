import router from './index';
import { Route } from 'vue-router';

const whiteList = [
    '/login'
];

router.beforeEach(async (to: Route, from: Route, next: any) => {
    if (localStorage.getItem('qymanagementtoken')) {
        if (to.path === '/login') {
            next({ path: '/' });
        } else {
            next();
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            next(`/login?redirect=${to.path}`);
        }
    }
});

router.afterEach((to: Route) => {
    document.title = to.meta ? (to.meta.title || 'WS Project Admin') : "";
});
