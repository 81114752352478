import Request from "@/utils/API/Request";

export default class Download {

    public static getDownloadList(params: any) {
        return Request({
            url: `/core/api/v1/admin/c/export/admin/page`,
            method: "get",
            params
        });
    }

    public static exports(data: any) {
        let url = data.t ? data.type.url + "/" + data.t : data.type.url;
        data.type = data.type.tag;

        return Request({
            url: url,
            method: "post",
            data
        });
    }
}
