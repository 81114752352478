import Request from "../Request";

export default class AssetsApi {
    public static baseUrl = '/core/api/v1/admin/b/tool';

    /*获取图片列表*/
    public static getFileList(params: any) {
        return Request({
            url: `${this.baseUrl}/page`,
            method: "get",
            params
        });
    }

    /*上传图片文件*/
    public static uploadFile(data: any) {
        return Request({
            url: `${this.baseUrl}/upload`,
            method: "post",
            data
        });
    }

}
