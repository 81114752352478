/*
 * 验证相关
 */

export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path);

// 正整数【不包含0】
export const integer = /^[1-9]\d*$/;

//整数【包含0】
export const integer0 = /^([1-9]\d*|[0]{1,1})$/;

// 密码【6-20位 包括特殊字符】
export const password = /^[A-Za-z0-9!#$%^&*@.~,]{6,30}$/;

// 密码【8位以上 同时包含大小写字母以及数字】
export const definePassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;

//账号
export const username = /^[A-Za-z0-9]+$/;

//手机号码
export const phone = /^1[34578]\d{9}$/;